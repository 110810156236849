<template>
  <date class="date" :class="{ white: color }">
    <div class="date__wrapper">
      <div class="date__content">
      
        <div class="date__hl">  Wir freuen uns auf Sie am</div>
        <div class="date__sl">  <span class="date__day"> Freitag 8.12.23</span>,  <span class="date__uhr">15 - 20 Uhr </span> <br>
          <span class="date__day">Samstag 9.12.23</span>, <span class="date__uhr">11 - 19 Uhr </span></div>
      
      </div>
    </div>
  </date>
</template>

<script>
import getPathFunction from "@/utilities/composition/getPathFunction";
import contactInformation from "@/utilities/siteinformation/contactInformation";
import { useStore } from "vuex";

import { onMounted } from "@vue/runtime-core";
export default {
  props: { nologo: Boolean, color: String },
  setup() {
    const store = useStore();
    const kontakt = contactInformation;
    const publicPath = process.env.BASE_URL;
    const getMail = "mailto:" + kontakt.email;
    const getPhone = "tel:" + kontakt.phone;

    const { getPath } = getPathFunction();

    function prefixPath(el) {
      return publicPath + el;
    }

    function closeMenu() {
      store.commit("setMenuState", false);
    }

    onMounted(() => {
      let els = document.querySelectorAll(".date__item");
      els.forEach((element) => {
        element.addEventListener("click", closeMenu);
      });
    });
    return { kontakt, getMail, getPhone, prefixPath, getPath };
  },
};
</script>

<style lang="scss">
.date {
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 20;

  margin-bottom: 0 !important;

 
  padding-left: 10px;
  padding-right: 10px;
  text-align: center;
}

.date__content{
  width:100%;
  padding: 10px;
  color: white;

  
}
.date__wrapper{
  margin: 0 auto;
  max-width: 500px;

  background-color:rgba($color:  $main-color-1, $alpha: 0.7);
  border-radius: 10px 10px 0 0;
  overflow: hidden;


}
.date__hl{
  font-family: $font-family-herohl;
  padding-bottom: 5px;
}

.date__day{
  display: inline-block;
  // min-width: 145px;
  // text-align: right;
}
.date__uhr{
  display: inline-block;
  // min-width: 145px;
  // text-align: left;
}

</style>
